.icon {
	display: inline-block;
	vertical-align: middle;
	line-height: inherit;
	fill: currentColor;
	position: relative;

	&-logo-left,
	&-logo-right {
		@include size(7px, 22px); }

	&-logo {
		@include size(184px, 23px); }

	&-logo-drdek {
		@include size(160px, 21px); }

	&-fast {
		@include size(61px, 49px); }

	&-coins {
		@include size(42px); }

	&-diamont {
		@include size(48px, 40px); }

	&-email {
		@include size(40px, 30px); }

	&-flash {
		@include size(26px, 40px); }

	&-heart {
		@include size(45px, 40px); }

	&-loop {
		@include size(40px); }

	&-phone {
		@include size(41px, 40px); }

	&-plus {
		@include size(29px); }

	&-shield {
		@include size(35px, 40px); }

	&-diamond {
		@include size(48px, 40px); }

	&-code {
		@include size(51px, 31px); }

	&-chip {
		@include size(43px, 44px); }

	&-done {
		@include size(42px); }

	@include media($break768) {
		&-logo-left,
		&-logo-right {
			@include size(9px, 29px); }

		&-logo {
			@include size(208px, 26px); }

		&-logo-drdek {
			@include size(198px, 26px); } } }
