.hint {
	padding: 0;
	margin: 0;
	background: none;
	border: none;
	display: inline-block;
	position: relative;
	cursor: help;
	font-size: 1em;
	font-family: inherit;
	outline: 0;
	transition: .3s ease background-color, .3s ease color;

	&:before,
	&:after {
		pointer-events: none;
		opacity: 0;
		transition: .3s ease opacity; }

	&:before {
		content: attr(data-title);
		font-size: 14px;
		color: #fff;
		padding: 3px 5px;
		position: absolute;
		top: -25px;
		left: 50%;
		transform: translate(-50%, 0);
		white-space: nowrap;
		background-color: $c-text; }

	&:after {
		content: '';
		position: absolute;
		top: -1px;
		left: 50%;
		transform: translate(-50%, 0);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 5px 0 5px;
		border-color: $c-text transparent transparent transparent; }

	u {
		text-decoration: none;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 2px;
			border-bottom: 1px dashed $c-main;
			opacity: 0.5; } }

	&:hover,
	&:active,
	&:focus {
		background-color: red;
		color: #fff;

		u:after {
			border-bottom-color: transparent; }

		&:before,
		&:after {
			opacity: 1;
			pointer-events: auto; } } }
