.cards {
	max-width: 1300px;
	padding: 0 10px;
	margin: 50px auto;
	overflow: hidden; }

.cards__list {
	margin-top: 15px;
	margin-bottom: -40px; }

.cards__item {
	margin-bottom: 20px;
	border-left: 5px solid #E2E2E2;
	padding: 15px 15px 15px 20px;
	opacity: 0;
	transform: translate(-10px, 0);
	transition: .6s ease opacity, .6s ease transform;

	&.visible {
		opacity: 1;
		transform: translate(0, 0); }

	&-icon {
		height: 40px; }

	&-title {
		text-transform: uppercase;
		font-family: $f-secondary;
		font-size: 18px;
		letter-spacing: 0.3px;
		margin: 10px 0 5px;
		line-height: 1; }

	&-text {
		margin: 0;
		color: $c-text-light;
		font-size: 15px;
		line-height: 1.5; }

	&-text a {
		color: currentColor;
		transition: .3s ease background-color, .3s ease color;
		text-decoration: none;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 2px;
			left: 0;
			height: 1px;
			background-color: $c-main; }

		&:hover {
			background-color: $c-main;
			color: #fff; } } }

@include media($break768) {
	.cards {
		padding: 0 15px;
		margin: 60px auto; }

	.cards__list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 40px;
		margin-bottom: -35px; }

	.cards__item {
		width: calc(100% / 2 - 10px);
		margin-bottom: 35px;

		&-title {
			font-size: 22px;
			margin: 15px 0 10px; }

		&-text {
			font-size: 16px; }

		&-listitem {
			font-size: 15px; }

		&:nth-child(2),
		&:nth-child(5) {
			transition-delay: .2s; }

		&:nth-child(3),
		&:nth-child(6) {
			transition-delay: .2s; } } }

@include media($break992) {
	.cards {
		width: calc(100% - 80px);
		margin: 80px auto; }

	.cards__list {
		margin-bottom: -40px; }

	.cards__item {
		width: calc(100% / 3 - 25px);
		margin-bottom: 40px; } }
