// A helper mixin for @media queries
//sage:
//@include media(mediaQuery [, mediaQuery]*)
//	foo: bar
//	…

//where mediaQuery is a sass list of one of
//- string of a classic css feature query
//- unitless number (will be interpreted as the pixel argument for min-width)
//- number with a special unit 'M' (e.g. 123M) (will be interpreted as the pixel argument for max-width)

//xamples:
//@include media($break480 $break768M, $break992 $break1200M)
//// @media (min-width: 480px) and (max-width: 767px), (min-width: 992px) and (max-width: 1199px)

//@include media(123)
//// @media (min-width: 123px)

//@include media(123M)
//// @media (max-width: 456px)

//@include media($break480 '(screen)', 300 500M, $landscape)
//// @media (min-width: 480px) and (screen), (min-width: 300px) and (max-width: 500px), (orientation: landscape)


@mixin media($breakpoints...) {
	$media: '';
	$disjunctionCount: length($breakpoints);

	@for $i from 1 through $disjunctionCount {
		$conjunction: nth($breakpoints, $i);
		$conjunctionCount: length($conjunction);

		@for $j from 1 through $conjunctionCount {
			$literal: nth($conjunction, $j);

			@if type-of($literal) == number {
				$literal: '(#{if(unit($literal) == 'M', 'max', 'min')}-width: #{$literal / ($literal * 0 + 1)}px)'; }

			$media: $media + $literal + if($j == $conjunctionCount, '', ' and '); }

		$media: $media + if($i == $disjunctionCount, '', ', '); }

	@media #{$media} {
		@content; } }
