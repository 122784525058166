*, ::after, ::before {
	box-sizing: border-box; }

html {
	scroll-behavior: smooth; }

body {
	margin: 0;
	color: $c-text;
	font-family: $f-primary;
	font-size: $base-font-size;
	line-height: $base-line-height;
	background-color: #fff;

	::selection {
		background-color: $c-main;
		color: #fff; } }

img {
	max-width: 100%; }
