.header {
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;

	&__bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $c-text; }

	&__img {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center 25%;
		opacity: 0;
		animation: 1.75s showOpacity ease forwards; }

	&__main {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		padding: 35px 20px 35px;
		background-color: #fff;
		position: relative;
		z-index: 5;
		margin: 80px 20px;
		text-align: center; }

	&__icon {
		margin-bottom: 10px;
		overflow: hidden;

		svg {
			animation: .4s showTitle ease forwards; } }

	&__title {
		font-family: $f-secondary;
		color: $c-text;
		text-transform: uppercase;
		font-size: 32px;
		line-height: 1.4;
		font-weight: 900;
		letter-spacing: 2.5px;
		margin: 0 0 5px;

		div {
			overflow: hidden;

			span {
				display: block;
				transform: translate(0, 100px);
				animation: .4s showTitle ease forwards; }

			&:nth-child(1) span {
				animation-delay: .2s; }

			&:nth-child(2) span {
				animation-delay: .3s; }

			&:nth-child(3) span {
				animation-delay: .4s; } } }

	&__claim {
		color: $c-text-light;
		margin: 0;
		font-size: 16px;
		letter-spacing: .5px;
		opacity: 0;
		animation: .75s showOpacity ease forwards;
		animation-delay: .5s;

		a {
			color: $c-main;
			text-decoration: underline;

			&:hover {
				color: $c-main-hover; } } }

	@include media($break768M) {
		&__claim {
			br {
				display: none; } } }

	@include media($break768) {
		&__main {
			padding: 40px;
			margin: 100px 20px;
			width: 450px; }

		&__title {
			font-size: 44px;
			letter-spacing: 3px; }

		&__claim {
			font-size: 18px;
			letter-spacing: .2px; } }

	@include media($break992) {
		&__main {
			padding: 40px;
			margin: 120px 20px;
			width: 480px; }

		&__title {
			font-size: 43px;
			letter-spacing: 5px; }

		&__claim {
			font-size: 18px;
			letter-spacing: .7px; } }

	@include media($break1800) {
		&__main {
			width: 570px;
			margin: 160px 20px; }

		&__title {
			font-size: 50px; }

		&__claim {
			font-size: 20px; } } }
