// Global
$base-font-size: 16px;
$base-line-height: 1.8;

// Fonts
$f-primary: 'Source Sans Pro', sans-serif;
$f-secondary: 'Roboto Condensed', sans-serif;

// Colors
$c-main: #F90A0A;
$c-main-hover: #ff0071;

$c-text: #171717;
$c-text-light: #757575;


// Breakpoints
$break320: '(min-width: 320px)';
$break480: '(min-width: 480px)';
$break768: '(min-width: 768px)';
$break992: '(min-width: 992px)';
$break1200: '(min-width: 1200px)';
$break1400: '(min-width: 1400px)';
$break1800: '(min-width: 1800px)';
$break2560: '(min-width: 2560px)';

$break320M: '(max-width: 319px)';
$break480M: '(max-width: 479px)';
$break768M: '(max-width: 767px)';
$break992M: '(max-width: 991px)';
$break1200M: '(max-width: 1199px)';
$break1400M: '(max-width: 1399px)';
$break2560M: '(max-width: 2559px)';

$break768-992: '(min-width: 768px) and (max-width: 991px)';
$break768-1200: '(min-width: 768px) and (max-width: 1199px)';

$landscape: '(orientation: landscape)';
$portrait: '(orientation: portrait)';

