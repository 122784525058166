@mixin p($l : 0, $t : 0) {
	left: $l;
	top: $t; }

.canvas {
	div {
		position: absolute;
		opacity: 0.3;
		transform: scale(0) rotate(180deg);

		svg {
			width: 30px;
			height: 30px; }

		.visible & {
			transform: scale(1);
			transition: 1s ease all;

			&.enter {
				color: $c-main;
				opacity: 1;
				transform: rotate(260deg) scale(1.5);
				transition: .8s ease all; } } }

	@include media($break992M) {
		div:nth-child(1) {
			@include p(5%, 5%); }
		div:nth-child(2) {
			@include p(8%, 20%); }
		div:nth-child(3) {
			@include p(8%, 30%); }
		div:nth-child(4) {
			@include p(15%, 50%); }
		div:nth-child(5) {
			@include p(45%, 80%); }
		div:nth-child(6) {
			@include p(24%, 77%); }
		div:nth-child(7) {
			@include p(85%, 50%); }
		div:nth-child(8) {
			@include p(76%, 22%); }
		div:nth-child(9) {
			@include p(3%, 80%); }
		div:nth-child(10) {
			@include p(38%, 93%); }
		div:nth-child(11) {
			@include p(17%, 61%); }
		div:nth-child(12) {
			@include p(45%, 1%); }
		div:nth-child(13) {
			@include p(22%, 7%); }
		div:nth-child(14) {
			@include p(88%, 29%); }
		div:nth-child(15) {
			@include p(32%, 17%); }
		div:nth-child(16) {
			@include p(4%, 57%); }
		div:nth-child(17) {
			@include p(77%, 2%); }
		div:nth-child(18) {
			@include p(9%, 70%); }
		div:nth-child(19) {
			@include p(32%, 26%); }
		div:nth-child(20) {
			@include p(11%, 92%); }
		div:nth-child(21) {
			@include p(66%, 7%); }
		div:nth-child(22) {
			@include p(94%, 4%); }
		div:nth-child(23) {
			@include p(79%, 60%); }
		div:nth-child(24) {
			@include p(54%, 22%); }
		div:nth-child(25) {
			@include p(84%, 78%); }
		div:nth-child(26) {
			@include p(76%, 92%); }
		div:nth-child(27) {
			@include p(62%, 88%); }
		div:nth-child(28) {
			@include p(44%, 69%); }
		div:nth-child(29) {
			@include p(68%, 68%); }
		div:nth-child(30) {
			@include p(97%, 90%); } }

	@include media($break992) {
		div:nth-child(1) {
			@include p(5%, 5%); }
		div:nth-child(2) {
			@include p(8%, 20%); }
		div:nth-child(3) {
			@include p(8%, 30%); }
		div:nth-child(4) {
			@include p(15%, 50%); }
		div:nth-child(5) {
			@include p(45%, 80%); }
		div:nth-child(6) {
			@include p(24%, 77%); }
		div:nth-child(7) {
			@include p(85%, 50%); }
		div:nth-child(8) {
			@include p(76%, 22%); }
		div:nth-child(9) {
			@include p(3%, 80%); }
		div:nth-child(10) {
			@include p(38%, 93%); }
		div:nth-child(11) {
			@include p(17%, 61%); }
		div:nth-child(12) {
			@include p(45%, 5%); }
		div:nth-child(13) {
			@include p(22%, 7%); }
		div:nth-child(14) {
			@include p(82%, 24%); }
		div:nth-child(15) {
			@include p(18%, 17%); }
		div:nth-child(16) {
			@include p(4%, 57%); }
		div:nth-child(17) {
			@include p(77%, 2%); }
		div:nth-child(18) {
			@include p(9%, 66%); }
		div:nth-child(19) {
			@include p(32%, 26%); }
		div:nth-child(20) {
			@include p(11%, 92%); }
		div:nth-child(21) {
			@include p(66%, 7%); }
		div:nth-child(22) {
			@include p(94%, 4%); }
		div:nth-child(23) {
			@include p(67%, 50%); }
		div:nth-child(24) {
			@include p(54%, 22%); }
		div:nth-child(25) {
			@include p(84%, 78%); }
		div:nth-child(26) {
			@include p(76%, 92%); }
		div:nth-child(27) {
			@include p(62%, 88%); }
		div:nth-child(28) {
			@include p(64%, 74%); }
		div:nth-child(29) {
			@include p(68%, 68%); }
		div:nth-child(30) {
			@include p(97%, 90%); }
		div:nth-child(31) {
			@include p(95%, 46%); }
		div:nth-child(32) {
			@include p(88%, 38%); }
		div:nth-child(33) {
			@include p(33%, 66%); }
		div:nth-child(34) {
			@include p(2%, 38%); }
		div:nth-child(35) {
			@include p(36%, 3%); }
		div:nth-child(36) {
			@include p(25%, 37%); }
		div:nth-child(37) {
			@include p(89%, 70%); }
		div:nth-child(38) {
			@include p(70%, 33%); }
		div:nth-child(39) {
			@include p(96%, 25%); }
		div:nth-child(40) {
			@include p(57%, 2%); }
		div:nth-child(41) {
			@include p(57%, 99%); }
		div:nth-child(42) {
			@include p(99%, 80%); }
		div:nth-child(43) {
			@include p(87%, -1%); }
		div:nth-child(44) {
			@include p(13%, -2%); }
		div:nth-child(45) {
			@include p(-1%, 63%); }
		div:nth-child(46) {
			@include p(22%, 97%); }
		div:nth-child(47) {
			@include p(90%, 96%); }
		div:nth-child(48) {
			@include p(55%, 76%); }
		div:nth-child(49) {
			@include p(50%, -2%); }
		div:nth-child(50) {
			@include p(37%, 15%); }
		div:nth-child(51) {
			@include p(-1%, 1%); }
		div:nth-child(52) {
			@include p(77%, 60%); }
		div:nth-child(53) {
			@include p(-1%, 94%); }
		div:nth-child(54) {
			@include p(2%, 15%); }
		div:nth-child(55) {
			@include p(90%, 14%); }
		div:nth-child(56) {
			@include p(78%, 44%); }
		div:nth-child(57) {
			@include p(76%, 80%); }
		div:nth-child(58) {
			@include p(95%, 67%); }
		div:nth-child(59) {
			@include p(28%, 90%); }
		div:nth-child(60) {
			@include p(53%, 92%); } } }
