.footer {
	width: calc(100% - 20px);
	margin: 50px auto;
	border: 1px solid #000;
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 160px);
	text-align: center;
	position: relative;

	@include media($break768) {
		margin: 60px auto;
		width: calc(100% - 40px); }

	@include media($break992) {
		margin: 80px auto;
		width: calc(100% - 80px); }

	&__center {
		opacity: 0;
		transition: 1.2s ease opacity .2s;

		.logo {
			pointer-events: none; }

		.visible & {
			opacity: 1; } }

	&__canvas {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden; }

	&__data {
		position: relative;
		z-index: 10;

		&-ic {
			a {
				pointer-events: none;

				&:after {
					display: none; } } }

		p {
			margin: 15px 0 0;
			font-size: 19px;
			color: $c-text-light;
			line-height: 1.6;

			@include media($break768) {
				margin: 20px 0 0;
				font-size: 23px; } }

		a {
			position: relative;
			color: $c-text;
			text-decoration: none;
			transition: .3s ease background-color, .3s ease color;

			&:after {
				content: '';
				position: absolute;
				right: 0;
				bottom: 2px;
				left: 0;
				height: 1px;
				background-color: $c-main; }

			&:hover {
				background-color: $c-main;
				color: #fff; } } } }
