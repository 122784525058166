@keyframes showTitle {
	0% {
		transform: translate(0, 100px); }

	100% {
		transform: translate(0, 0); } }

@keyframes showOpacity {
	0% {
		opacity: 0; }

	100% {
		opacity: 1; } }

@keyframes logoMobile {
	0% {
		width: 0; }

	100% {
		width: 281px; } }

@keyframes logo {
	0% {
		width: 0; }

	100% {
		width: 350px; } }
