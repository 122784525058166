.boxes {
	max-width: 1400px;
	padding: 0 10px;
	margin: 50px auto;
	overflow: hidden; }

.boxes__list {
	margin-top: 15px;
	margin-bottom: -40px; }

.boxes__item {
	margin-bottom: 20px;
	background-color: #F7F7F7;
	padding: 40px 20px;
	opacity: 0;
	transition: .6s ease opacity;

	&.visible {
		opacity: 1; }

	&-icon {
		height: 40px; }

	&-title {
		text-transform: uppercase;
		font-family: $f-secondary;
		font-size: 18px;
		letter-spacing: 0.3px;
		margin: 10px 0 5px;
		line-height: 1; }

	&-text {
		margin: 0;
		color: $c-text-light;
		font-size: 15px;
		line-height: 1.5; }

	&-text a {
		color: currentColor;
		transition: .3s ease background-color, .3s ease color;
		text-decoration: none;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 2px;
			left: 0;
			height: 1px;
			background-color: $c-main; }

		&:hover {
			background-color: $c-main;
			color: #fff; } } }

@include media($break768) {
	.boxes {
		padding: 0 15px;
		margin: 60px auto; }

	.boxes__list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 40px;
		margin-bottom: -35px; }

	.boxes__item {
		width: calc(100% / 2 - 20px);
		margin-bottom: 35px;
		padding: 45px 15px 20px;

		&-title {
			font-size: 20px;
			margin: 15px 0 10px; }

		&-text {
			font-size: 15px; }

		&-listitem {
			font-size: 15px; }

		&:nth-child(2) {
			transition-delay: .1s; }

		&:nth-child(3) {
			transition-delay: .2s; }

		&:nth-child(4) {
			transition-delay: .3s; } } }

@include media($break768-992) {
		.boxes__item {
			&:nth-child(odd) {
				margin-right: 40px; } } }


@include media($break992) {
	.boxes {
		width: calc(100% - 80px);
		margin: 80px auto; }

	.boxes__list {
		margin-bottom: -40px; }

	.boxes__item {
		width: calc(100% / 4 - 25px);
		padding: 85px 15px 45px 20px;
		margin-bottom: 40px;

		&-title {
			font-size: 22px; }

		&-text {
			font-size: 16px; } } }
