.title {
	margin: 0;
	font-size: 28px;
	font-weight: 900;
	font-family: $f-secondary;
	letter-spacing: 0;
	text-align: center;

	&:before {
		content: '[';
		color: $c-main;
		margin-right: 10px;
		font-size: 28px;
		position: relative;
		bottom: 1px; }

	@include media($break768) {
		font-size: 30px;

		&:before {
			font-size: 30px; } } }
