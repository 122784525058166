.logo {
	padding: 23px 0 25px;
	display: flex;
	justify-content: center;

	.icon-logo-left,
	.icon-logo-right, {
		position: relative;
		bottom: 1px; }

	&__img {
		position: relative;
		margin: 0 4px 0 7px;
		top: 2px;
		font-size: 24px;
		line-height: 0.8;
		font-weight: 900;
		font-family: $f-secondary;
		white-space: nowrap;

		@include media($break768) {
			font-size: 30px; } }

	&--drdek {
		.logo__img {
			top: 0; } }

	&--animate {
		.logo__img {
			width: 0;
			overflow: hidden;
			animation: 1s logoMobile ease forwards; } }

	&--large {
		transform: scale(1.2);
		padding: 0; }

	@include media($break768) {
		padding: 40px 0;

		.icon-logo-left,
		.icon-logo-right, {
			bottom: 2px; }

		&--animate {
			.logo__img {
				animation: 1s logo ease forwards; } }

		&--large {
			transform: scale(1.5);
			padding: 0; }

		&--drdek {
			.logo__img {
				top: 1px; } } } }
